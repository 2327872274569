import { removeColon } from '@monorepo/control/shared';

export const QUERY_PARAMS = Symbol('query params');

export const DICTIONARIES_API = {
  CONTROLLER: () => {
    return {
      url: `dictionaries` as const,
    };
  },

  GET_PRODUCT_DEFECTS: () => {
    return {
      url: `product-defects` as const,
    };
  },

  GET_DEFECT_ELIMINATION: () => {
    return {
      url: `defect-eliminations` as const,
    };
  },

  GET_DEFECT_COMMENTS: () => {
    return {
      url: `defect-comments` as const,
    };
  },

  GET_PRODUCT_ZONES: () => {
    return {
      url: `product-zones` as const,
    };
  },

  GET_CONDITIONS: () => {
    return {
      url: `conditions` as const,
    };
  },

  GET_BUSINESS_PARTNERS: () => {
    return {
      url: 'business-partners' as const,
    };
  },

  GET_ACCEPTANCE_STATUSES: () => {
    return {
      url: 'acceptance-statuses' as const,
    };
  },

  GET_ACCEPTANCE_TYPES: () => {
    return {
      url: 'acceptance-types' as const,
    };
  },

  GET_SSCC_TYPES: () => {
    return {
      url: 'sscc-types' as const,
    };
  },

  GET_TRADE_ITEM_GROUPS: () => {
    return {
      url: 'trade-item-groups' as const,
    };
  },

  GET_DEPARTMENTS: () => {
    return {
      url: 'departments' as const,
    };
  },

  GET_OFF_SYSTEM_STEPS: () => {
    return {
      url: 'off-system-steps' as const,
    };
  },
} as const;

type UsersInputProps = {
  BY_ROLE: { role: 'master' };
};
export const DICTIONARIES_USERS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/users` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },

  BY_ROLE: (params: UsersInputProps['BY_ROLE']) => {
    const { role } = params;
    return {
      params: removeColon(params),
      url: `role/${role}` as const,
    };
  },
};
// type UsersInputProps = {
//   BY_ROLE: { role: 'master' };
// };
export const DICTIONARIES_INVENTORY_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/inventory` as const,
    };
  },

  TYPES: () => {
    return {
      url: `types` as const,
    };
  },

  STATUSES: () => {
    return {
      url: `statuses` as const,
    };
  },

  COUNTING_TYPES: () => {
    return {
      url: `counting-types` as const,
    };
  },

  LIST_STATUSES: () => {
    return {
      url: `list-statuses` as const,
    };
  },
};

type UsersTopologyInputProps = {
  SECTORS_BY_ZONE: { [QUERY_PARAMS]: ['zoneId'] };
};
export const DICTIONARIES_TOPOLOGY_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/topology` as const,
    };
  },

  SECTORS_BY_ZONE: (params: UsersTopologyInputProps['SECTORS_BY_ZONE']) => {
    return {
      queryParams: params[QUERY_PARAMS],
      url: `sectors` as const,
    };
  },

  ZONES: () => {
    return { url: `zones` as const };
  },

  PLACE_TYPES: () => {
    return { url: `place-types` as const };
  },

  FOR_LOCK_OP_ZONES: () => {
    return { url: `zones/for_lock_op` as const };
  },

  INVENTORY_ZONES: () => {
    return { url: `zones/inventory` as const };
  },

  STORAGE_ZONES: () => {
    return { url: `zones/for_storage` as const };
  },
};

export const DICTIONARIES_ORDERS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/orders` as const,
    };
  },

  ORDER_TYPES: () => {
    return {
      url: `order-types` as const,
    };
  },

  ORDER_INTEGRATION_TYPES: () => {
    return {
      url: `order-integration-types` as const,
    };
  },

  ORDER_STATUSES: () => {
    return {
      url: `order-statuses` as const,
    };
  },

  ORDER_INTEGRATION_STATUSES: () => {
    return {
      url: `order-integration-statuses` as const,
    };
  },
};

export const DICTIONARIES_SUPERVISORS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/supervisors` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_MOVEMENT_DOCUMENT_STATUS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/movement-status` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_MOVEMENT_DOCUMENT_OBJECTS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/movement-objects` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_EMPLOYEE_MISTAKES_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/employee-mistakes` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_LIST_GROUPS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/list-groups` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },

  INTEGRATION: () => {
    return {
      url: `for-integration` as const,
    };
  },
}

export const DICTIONARIES_TRANSPORT_COMPANIES_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/transport-companies` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },

  GET_INTEGRATION_COMPANIES: () => {
    return {
      url: `for-integration` as const,
    };
  },
}

export const DICTIONARIES_REGISTRY_TYPE_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/registry-types` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_REGISTRY_STATUS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/registry-statuses` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_MESSAGE_STATUS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/message-statuses` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_PRINTERS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/printers` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_PRINT_FORMS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/print-forms` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },
}

export const DICTIONARIES_PLACES_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    return {
      url: `${rootController}/places` as const,
    };
  },

  GET_FOR_PRINTERS: () => {
    return {
      url: `for-printers` as const,
    };
  },
}

export const DICTIONARIES_CONDITIONS_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    const domain = DICTIONARIES_API.GET_CONDITIONS().url;
    return {
      url: `${rootController}/${domain}` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },

  INTEGRATION: () => {
    return {
      url: `for-integration` as const,
    };
  },
}

export const DICTIONARIES_BUSINESS_PARTNER_API = {
  CONTROLLER: () => {
    const rootController = DICTIONARIES_API.CONTROLLER().url;
    const domain = DICTIONARIES_API.GET_BUSINESS_PARTNERS().url;
    return {
      url: `${rootController}/${domain}` as const,
    };
  },

  ALL: () => {
    return {
      url: `` as const,
    };
  },

  INTEGRATION: () => {
    return {
      url: `for-integration` as const,
    };
  },
}
