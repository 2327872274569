import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { ErrorStateMatcher, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { StylesProviderModule } from '@monorepo/ui/providers/styles-provider';
import {
  AuthInterceptor,
  BlockedItemsInterceptor,
} from '@monorepo/ui/services/interceptors';

import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NxModule } from '@nx/angular';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import * as Sentry from '@sentry/angular-ivy';

import { ServiceWorkerModule } from '@angular/service-worker';
import { DictionariesServicesModule } from '@monorepo/dictionaries/ui';
import {
  API_URL,
  DirtyTouchedErrorStateMatcher,
  IS_PROD,
} from '@monorepo/ui/core';
import { ru } from 'date-fns/locale';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import {
  MAT_SELECTSEARCH_DEFAULT_OPTIONS,
  MatSelectSearchOptions,
} from 'ngx-mat-select-search';
import { WMSTranslateModule } from './translate.module';
import {NgxsModule, provideStore} from '@ngxs/store';
import {provideInterfaceSize} from '@12stz/ui-kit/tokens';
import {provideToast} from "@12stz/ui-kit/organisms/toast";
import {ConfirmModule} from "@12stz/ui-kit/organisms/confirm";

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    StylesProviderModule,
    AppRoutingModule,
    MatDialogModule,
    MatDateFnsModule,
    // NxModule.forRoot(),
    NgxsModule.forRoot(),
    DictionariesServicesModule, // provide NgRx store for dictionaries
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    NgxMaskModule.forRoot(maskConfig),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    WMSTranslateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ConfirmModule.forRoot(),
  ],
  providers: [
    provideInterfaceSize('medium'),
    provideToast({
      duration: 3000,
    }),
    { provide: MAT_DATE_LOCALE, useValue: ru },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        console.log('Sentry Init');
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockedItemsInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: 480,
        width: '100%',
        hasBackdrop: true,
      } as MatDialogConfig,
    },
    {
      provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
      useValue: <MatSelectSearchOptions>{
        placeholderLabel: 'Найти',
        noEntriesFoundLabel: 'Не найдено',
        indexAndLengthScreenReaderText: ' из ',
      },
    },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: IS_PROD, useValue: environment.production },
    { provide: ErrorStateMatcher, useClass: DirtyTouchedErrorStateMatcher },
    // provideStore(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
