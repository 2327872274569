import {NgModule} from "@angular/core";
import {OrderIntegrationStatusesService} from "./order-integration-statuses.service";
import {OrderIntegrationStatusesDictionaryState} from "./state/order-integration-statuses.state";
import {provideStates} from "@ngxs/store";
import {OrderIntegrationStatusesFacade} from "./state/order-integration-statuses.facade";

@NgModule({
  providers: [
    OrderIntegrationStatusesService,
    provideStates([OrderIntegrationStatusesDictionaryState]),
    OrderIntegrationStatusesFacade,
  ]
})
export class OrderIntegrationStatusesDictionaryModule {}
